<template>
  <div class="md:container md:mx-auto">
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "Container"
}
</script>

<style scoped>

</style>