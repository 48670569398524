<template>
  <div>
    <div class="bg-green-700 shadow-lg fixed top-0 w-full z-50">
      <top-bar/>
    </div>
    <div class="mt-10">
      <header-bar :header="header"/>
      <slot/>
    </div>
  </div>
</template>

<script>
import TopBar from "../../components/core/TopBar";
import HeaderBar from "../../components/core/HeaderBar";
export default {
  name: "BasicLayout",
  props:['header'],
  components: {HeaderBar, TopBar}
}
</script>

<style scoped>

</style>