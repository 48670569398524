<template>
  <header v-bind:class="[isSticky ? stickyClass : '']" id="f-navbar"  class="navbar sm:px-16 head  sm:bg-transparent bg-opacity-70 bg-green-700  font-semibold flex flex-wrap sm:justify-end  items-center sm:py-0 py-2">
    <div class="px-2">
      <label for="menu-toggle" class="pointer-cursor block text-white sm:hidden block">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </label>
      <input class="hidden" type="checkbox" id="menu-toggle" />
      <div class="hidden  sm:flex px-4  sm:items-center sm:w-auto w-full" id="menu">
        <nav>
          <ul class="sm:flex items-center justify-between text-base text-white pt-2 sm:pt-0">
            <li class="menu-item"><router-link class="sm:px-4 focus:outline-none sm:py-2 p-2 px-0 block border-b-2 border-transparent hover:border-white" to="/">Home</router-link></li>
            <li class="menu-item"><router-link class="sm:px-4 focus:outline-none sm:py-2 p-2 px-0 block border-b-2 border-transparent hover:border-white" to="/#news">News</router-link></li>
            <li class="menu-item sm:relative sm:mb-0 mb-2">
              <router-link class="sm:px-4 focus:outline-none sm:py-2 p-2 px-0 " to="#">Services</router-link>
              <div class="menu-subitem  hidden sm:absolute top-6 sm:w-40 p-4 sm:bg-green-700 bg-opacity-60 rounded-lg">
                <ul class="pl-4 sm:pl-0">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/#library-hours">Library Hours</router-link></li>
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/library-section">Library Sections</router-link></li>
                </ul>
              </div>
            </li>
            <li class="menu-item sm:relative sm:mb-0 mb-2" >
              <router-link class="sm:px-4 focus:outline-none sm:py-2 p-2 px-0 " to="#">Policy</router-link>
              <div class="menu-subitem  hidden sm:absolute top-6 sm:w-48 -left-16 p-4 sm:bg-green-700 bg-opacity-60 rounded-lg">
                <ul class="pl-4 sm:pl-0">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/policy/#library-id-card">Library ID Card</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/policy/#control-procedure">Control Procedure</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/policy/#borrowing-rights-and-responsibilities">Borrowing Rights and Responsibilities</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/policy/#reserve-books-for-overnight-loan">Reserve Books for Overnight Loan</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/policy/#fines-and-penalties">Fines and Penalties</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/policy/#losses-and-mutilation">Losses and Mutilation</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/policy/#visitors">Visitors</router-link></li>
                </ul>
              </div>
            </li>
            <li class="menu-item sm:relative sm:mb-0 mb-2" >
              <router-link class="sm:px-4 focus:outline-none sm:py-2 p-2 px-0 " to="#">About</router-link>
              <div class="menu-subitem  hidden sm:absolute top-6 sm:w-48 -left-16 p-4 sm:bg-green-700 bg-opacity-60 rounded-lg">
                <ul class="pl-4 sm:pl-0">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/history/main-history">History</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/vmgo">VMGO</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/organizational-chart">Organizational Chart</router-link></li>
                  <hr class="w-full text-green-500">
                  <li><router-link class="px-0 block border-b-2 border-transparent hover:border-white" to="/#contacts">Contact Information</router-link></li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
window.Vue = require('vue');
export default {
name: "TopBar",
  el: '#f-navbar',
  data:()=>({
    isSticky: false,
    stickyClass: 'is_sticky',
    scrollPosition:0
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    handleScroll(event){
      console.log(event);
      this.scrollPosition = window.scrollY
      if(this.scrollPosition>=100){
        this.isSticky=true
      }else{
        this.isSticky=false
      }
    }
  }
}
</script>

<style scoped>
  #menu-toggle:checked + #menu {
    display: block;

  }
  .menu-item:hover .menu-subitem{
    display: block;
  }
  .navbar.is_sticky {
    @apply fixed top-0 bg-green-700 bg-opacity-90 w-full text-right shadow-lg z-50;
    z-index: 999;
  }

</style>