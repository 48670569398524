<template>
  <basic-layout header="Calapan City Campus">
    <div class="bg-white bg-opacity-90">
      <container class="py-20 px-5">
        <div class="lg:grid lg:grid-cols-8">
          <div class="lg:col-span-6  overflow-hidden">
            <img class="rounded-2xl  shadow-lg w-full" src="../../../assets/images/charts/calapan-chart.jpg" alt="">
          </div>
          <div class="lg:col-span-2 px-6 ">
            <div class="bg-white rounded-xl overflow-hidden shadow-2x mt-10 lg:mt-0">
              <div class="text-center text-white bg-green-700 font-semibold  p-3 text-lg uppercase">
                <h2>Organizational Chart of every campus.</h2>
              </div>
              <div class="p-4">
                <div v-for="(link,index) in links" :key="index" class="flex text-cl items-center text-green-700 py-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                  </svg>
                  <router-link :to="link.link" class="">{{link.name}}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </container>
    </div>
  </basic-layout>
</template>

<script>
import BasicLayout from "../../layouts/BasicLayout";
import Container from "../../layouts/Container";
export default {
  name: "CalapanChart",
  components: {Container, BasicLayout},
  data:()=>({
    links: [
      {
        name: 'Main Campus',
        link: '/chart/main-campus'
      },
      {
        name: 'Calapan City Campus',
        link: '/chart/calapan-city-campus'
      },
      {
        name: 'Bongabong Campus',
        link: '/chart/bongabong-campus'
      },
    ]
  })
}
</script>

<style scoped>

</style>