<template>
    <div class="bg-green-700  bg-opacity-80">
        <container class="p-4 flex items-center text-white p-10">
            <h1 class="uppercase text-3xl md:text-6xl font-bold"><span class="font-bold text-green-600">|</span>{{header}}</h1>
        </container>
    </div>
</template>

<script>
    import Container from "../../views/layouts/Container";
    export default {
        name: "HeaderBar",
        components: {Container},
        props:['header']
    }
</script>

<style scoped>
    .center{
        @apply flex  justify-center items-center content-center
    }
</style>